.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: "Helvetica Neue";
}

h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.logo {
  float: left;
}

.card {
  display: flex;
  flex-wrap: wrap;
}

.card-body {
  flex: 0 0 21%;
  margin: 10px;
  height: auto;
  background-color: rgba(0, 121, 192, .06);
  padding: 15px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 25px hsla(0, 0%, 39%, 0), 2px 2px 4px rgba(60, 60, 60, 0);
  border-bottom: 2px solid rgb(214, 31, 45);
}

.card-body:hover {
  background-color: rgba(0, 121, 192, 0);
  box-shadow: 0 2px 25px hsla(0, 0%, 39%, .1), 2px 2px 4px rgba(60, 60, 60, .05);
}

.vrt-audio-player-default {
  padding: 25px 0;
}


.card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
}

.card-program-title {
  font-size: 12px;
}

a, a:hover, a:focus, a:active{
  color: black;
  text-decoration: none;
}



